import React, { Component } from "react"
import { Link, navigate } from "gatsby"
import FacebookLogin from "react-facebook-login"
import GoogleLogin from "react-google-login"
import firebase from "../config/fbConfig"

import { connect } from "react-redux"
import { setUser, signOut } from "../state/app"

import Lottie from "react-lottie"
import * as animationData from "../animations/signin2.json"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./auth.scss"
import "./index.scss"

class Signin extends Component {
  state = {
    email: "",
    password: "",
    error: null,
    buttonContent: "Sign In",
  }

  componentDidMount() {
    if (this.props.isLoggedIn) {
      navigate("/")
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoggedIn) {
      navigate("/")
    }
  }

  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value })
  }

  handleLogin = e => {
    this.setState({ error: null, buttonContent: "Signing In..." })
    e.preventDefault()
    firebase
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(u => {
        let { email } = u.user

        let user = {
          email,
          expires_at: Date.now() + 31556926 / 1000,
        }

        this.props.setUser(user)
      })
      .then(() => navigate("/"))
      .catch(err => {
        this.setState({ buttonContent: "Sign In" })
        console.log(err)
        if (err.code === "auth/wrong-password") {
          this.setState({
            error: "The password and email combination is invalid",
          })
        } else if (err.code === "auth/user-not-found") {
          this.setState({
            error: "No account has been created for the entered email",
          })
        } else if (err.code === "auth/too-many-requests") {
          this.setState({
            error: "Too many unsuccessful attempts; please try again later",
          })
        } else {
          this.setState({ error: err.message })
        }
      })
  }

  responseFacebook = response => {
    if (response.status === "unknown" || response.status === "not_authorized") {
      return
    }
    const { email } = response

    let user = {
      email,
      expires_at: Date.now() + 31556926 / 1000,
    }

    this.props.setUser(user)
    navigate("/")
  }

  responseGoogle = response => {
    if (response.error) {
      return
    }
    const { email } = response.profileObj

    let user = {
      email,
      expires_at: Date.now() + 31556926 / 1000,
    }

    this.props.setUser(user)
    navigate("/")
  }

  render() {
    const animationOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    }

    return (
      <Layout location={this.props.location} crumbLabel="Sign In">
        <SEO
          title="Sign In"
          description="Sign into your IB Vine account with email / password or Google / Facebook."
        />
        <div className="hero auth-page">
          <div className="hero-content-right">
            <div className="auth-header">Welcome Back</div>
            <div className="content">
              Don't have an account?{" "}
              <Link className="active-link" to="/sign-up">
                Register
              </Link>
            </div>
            <form
              onSubmit={this.handleLogin}
              className="left-sub-content content"
            >
              <div className="form-group">
                <label>
                  <div>Email</div>
                  <div>
                    <input
                      className="auth-input"
                      onChange={this.handleChange}
                      value={this.state.email}
                      required
                      id="email"
                      name="email"
                      type="email"
                    />
                  </div>
                </label>
              </div>
              <div className="form-group">
                <label>
                  <div>Password</div>
                  <div>
                    <input
                      className="auth-input"
                      onChange={this.handleChange}
                      value={this.state.password}
                      required
                      id="password"
                      name="password"
                      type="password"
                    />
                  </div>
                </label>
              </div>
              {this.state.error ? (
                <div className="content error">{this.state.error}</div>
              ) : null}
              <div>
                <button type="submit" className="btn">
                  {this.state.buttonContent}
                </button>
              </div>
            </form>
            <div className="content">
              <p className="or-auth">OR</p>
              <div className="social-auth">
                <span className="active-link">
                  <FacebookLogin
                    appId="492108311651070"
                    textButton="Sign in Via Facebook"
                    fields="name,email,picture"
                    callback={this.responseFacebook}
                    isMobile={false}
                    autoLoad={false}
                    reAuthenticate={true}
                    authType="reauthenticate"
                    reRequest={true}
                  />
                </span>
                <span className="active-link ml-8">
                  <GoogleLogin
                    clientId="418767394256-9v6ap5mfee3ttujp12u73mh1pn46nhee.apps.googleusercontent.com"
                    buttonText="Sign in Via Google"
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    className="google-button"
                    isSignedIn={false}
                    prompt="select_account"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.app.isLoggedIn,
  }
}

export default connect(mapStateToProps, { setUser, signOut })(Signin)
